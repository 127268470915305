export default [{
    title: 'Propiedades',
    icon: 'TagIcon',
    children: [
        {
            title: 'Lista',
            route: 'propers-list',
            action: 'read',
		    resource: 'propers'
        }, 
        {
            title: 'Agregar',
            route: 'propers-create',
            action: 'create',
		    resource: 'propers'
        }
    ]
}];